<template>
  <div class="talents">
    <table></table>
    <div class="floor-title mt-20">
        <img src="../../assets/img/xnw/page1.png" alt="">
    </div>
    <!--能人介绍-->
    <div class="floor1 w-1280">
      <!-- <div class="floorTitle clearfix">
        <div class="floorName fl">能人介绍</div>
        <div class="more fr">
          <router-link to="/bestperson"
            >更多<i class="el-icon-arrow-right"></i
          ></router-link>
        </div>
      </div> -->
      <div class="floor1-main">
        <div class="cal-small">
          <ul
            class="cal-small-list clearfix"
            :class="{ istrans }"
            :style="`width:${261 * 10}px;transform:translateX(${transNum}px)`"
            @mouseenter="end()"
            @mouseleave="paly()"
          >
            <li
              class="cal-smal-item fl"
              :class="[{ isSlace: Math.abs(transNum / 261) + 2 === index },{ isSlaceMin: Math.abs(transNum / 261) + 1 === index },{isSlaceMax: Math.abs(transNum / 261) + 3 === index}]"
              v-for="(item, index) in calList"
              :key="index + 'cal'"
              @click="goTalent(item.id,'能人介绍',JSON.stringify(Talentsparams))"
            >
              <div class="star-pic">
                <el-image
                  :src="baseUrl + item.photoUrls"
                  style="width: 100%; height: 100%"
                  fit="cover"
                ></el-image>
              </div>
              <!-- <div class="star-info">
                <div class="star-name ellipsis">{{ item.title }}</div>
                <div class="star-intro">
                  {{ regHtml(item.content) }}
                </div>
              </div> -->
              <div
                class="ceng"
                v-show="!(Math.abs(transNum / 261) + 2 === index)"
              ></div>
            </li>
          </ul>
          <div class="cal-btn prev" @click="prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="cal-btn next" @click="next">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="pr-list">
        <div class="pr-item" v-for="item in subList" :key="item.id" @click="goTalent(item.id,'能人介绍',JSON.stringify(Talentsparams))">
          <div class="pr-imgbox">
            <el-image :src="baseUrl + item.photoUrls" style="width: 100%; height: 100%" fit="cover"></el-image>
          </div>
          <div class="pr-infos">
            <div class="pr-title">{{item.title}}</div>
            <div class="pr-info">{{regHtml(item.content)}}</div>
          </div>
        </div>
        <!-- <el-row :gutter="20">
          <el-col :span="4" v-for="item in subList" :key="item.id">
            <div class="pr-list" @click="goTalent(item.id,'能人介绍',JSON.stringify(Talentsparams))">
              <div class="pr-pic">
                <el-image
                  :src="baseUrl + item.photoUrls"
                  style="width: 100%; height: 100%"
                  fit="cover"
                ></el-image>
              </div>
              <div class="pr-info">
                <div class="pr-title">
                  {{ item.title }}
                </div>
                <div class="pr-text">
                  {{ regHtml(item.content) }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row> -->
      </div>
    </div>
    <div class="more" @click="$router.push('/bestperson')">
        <span>查看更多</span>
        <img src="../../assets/img/hot-icon/more.png" alt="">
    </div>
    <!--能人事迹-->
    <div style="background:#f1f5f9">
      <table></table>
      <div class="floor-title mt-20">
          <img src="../../assets/img/xnw/page2.png" alt="">
      </div>
      <div class="floor w-1280">
        <!-- <div class="floorTitle clearfix">
          <div class="floorName fl">能人事迹</div>
          <div class="more fr">
            <router-link to="/story"
              >更多<i class="el-icon-arrow-right"></i
            ></router-link>
          </div>
        </div> -->
        <div class="floor2-main mt-20">
          <div class="f2_top">
            <div class="videotag v_main">
              <video class="videos" :src="baseUrl + NRSJfirst.videoUrl" controls width="100%" height="100%"></video>
            </div>
            <div class="v_infos">
              <div>
                <div class="h3">NENG REN SHI JI</div>
                <div class="v_title">{{NRSJfirst.videoName}}</div>
              </div>
              
              <div class="v_info">{{regHtml(NRSJfirst.introduction)}}</div>
            </div>
          </div>
          <div class="f2_bottom">
            <videolist :datas="NRSJList" @changePlay="changePlay"></videolist>
          </div>
          <!-- <el-row :gutter="20">
            <el-col :span="15">
              <div class="floor2-h">
                <video class="videos" :src="baseUrl + NRSJfirst.videoUrl"></video>
                <div class="T_video" style="line-height: 530px;"><img :src="require('../../../public/img/video/play.png')" @click="onVideoPlay(baseUrl + NRSJfirst.videoUrl)" alt=""></div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="floor2-text-box">
                <div class="floor2-title">
                  <span class="iconfont icon-fuzhushuxian"></span>
                  <span>{{ NRSJfirst.videoName }}</span>
                </div>
                <div class="floor2-text">
                  {{ regHtml(NRSJfirst.introduction) }}
                </div>
                <div class="floor2-time">
                  <span class="iconfont icon-shijian"></span>
                  <span style="margin-left: 10px">{{
                    NRSJfirst.createdTime
                  }}</span>
                </div>
              </div>
              <el-row :gutter="15">
                <el-col :span="12" v-for="item in NRSJList" :key="item.id">
                  <div class="f2-smPic">
                    <video :src="baseUrl + item.videoUrl" class="videos"></video>
                    <div class="T_video" style="line-height: 195px;" >
                        <img :src="require('../../../public/img/video/play.png')" @click="onVideoPlay(`${baseUrl + item.videoUrl}`)" alt="">
                        </div>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row> -->
        </div>
      </div>
      <div class="more" @click="$router.push('/story')">
          <span>查看更多</span>
          <img src="../../assets/img/hot-icon/more.png" alt="">
      </div>
      <div class="pb-20"></div>
    </div>
    <!--三农经验-->
    <div class="floor3 w-1280">
      <table></table>
      <div class="floor-title mt-20">
          <img src="../../assets/img/xnw/page3.png" alt="">
      </div>
      <!-- <div class="floorTitle clearfix">
        <div class="floorName fl">三农经验</div>
        <div class="more fr">
          <router-link to="/sxjyList"
            >更多<i class="el-icon-arrow-right"></i
          ></router-link>
        </div>
      </div> -->
      <div class="mt-20">
        <el-row :gutter="30">
          <el-col :span="6" v-for="item in SXJYList" :key="item.id">
            <router-link :to="`/detail?id=${item.id}&type=三农经验&oParams=${JSON.stringify(SXJYsparams)}`">
              <div class="f3-box">
                <div class="innerbox">
                  <el-image
                    :src="baseUrl + item.photoUrls"
                    style="width: 100%; height: 100%"
                    fit="cover"
                  ></el-image>
                  <div class="inner-title ellipsis">{{ item.title }}</div>
                </div>
                <div class="f3-ceng">
                  <p>
                    <span>{{ item.title }}</span>
                  </p>
                  <p>{{ regHtml(item.content) }}</p>
                </div>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
      <div class="more" @click="$router.push('/sxjyList')">
          <span>查看更多</span>
          <img src="../../assets/img/hot-icon/more.png" alt="">
      </div>
      <div class="pb-20"></div>
    </div>
     <!--视频播放窗口-->
    <el-dialog title="" :visible.sync="dialogVideo" width="840px" class="videodlog" append-to-body @close="onVideoClear">
      <i class="el-icon-close icon_btn" @click="dialogVideo = false" style="background:transparent;position:absolute;top:5px;right:10px;font-size:24px;z-index:99999;color:#fff"></i>
      <div class="videoplayer" v-if="dialogVideo">
        <video-player class="bideo-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
      </div>
      <div class="video_List clearfix">
        <ul>
          <li v-for="item in videoList" class="fl" :key="item+'_key'" style="margin-left:15px">
            <div class="video_box">
              <video :src="item" style="width:100%;height:100%"></video>
              <div class="video_shade"><img :src="require('../../../public/img/video/play.png')" @click="onVideoPlay(item)"></div>
            </div>
            <div class="video_title">{{item.substr(item.lastIndexOf('\\')+1)}}</div>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { GetTalents, GetVideoInfoPage } from "@/api/xiangningqing";
import { regHtml } from "@/utils/reg";
import videolist from "@/components/xnw/videolist"
export default {
  components:{
    videolist
  },
  data() {
    return {
      transNum: 0,
      calList: [
        { img: require("../../../public/img/xnq/r3.jpg") },
        { img: require("../../../public/img/xnq/r5.jpg") },
        { img: require("../../../public/img/xnq/r1.jpg") },
        { img: require("../../../public/img/xnq/r4.jpg") },
        { img: require("../../../public/img/xnq/r2.jpg") },
      ],
      subList: [],
      SXJYList: [],
      NRSJfirst: {},
      NRSJList: [],
      istrans: true,
      actimg: "",
      dialogVideo: false,
      videoList: [],//多个视频列表
      playerOptions: {},

      Talentsparams: {
        currentPage: 1,
        filter: { title: "", infoType: 249252335243333 },
        pageSize: 17,
      },
      SXJYsparams: {
        currentPage: 1,
        filter: { title: "", infoType: 256582149574725 },
        pageSize: 8,
      },
      NRSJsparams: {
        currentPage: 1,
        filter: { title: "", infoType: 256581677129797 },
        pageSize: 8,
      },
      timer:null,
    };
  },
  computed: {
    baseUrl: () => process.env.VUE_APP_PHOTO_URL,
    regHtml: () => regHtml,
  },
  methods: {
    changePlay(row){
      this.NRSJfirst = row;
    },
    next() {
      // console.log(this.transNum, -this.calList.length * 261);
      if (this.transNum === (-this.calList.length * 261) / 2) {
        this.transNum = 0;
        this.istrans = false;
        setTimeout(() => {
          this.istrans = true;
          this.transNum -= 261;
          // this.actimg = this.imgdata[this.activeNum].img
        }, 1);
      } else {
        this.transNum -= 261;
        // this.actimg = this.imgdata[this.activeNum].img
      }
    },
    prev() {
      if (this.transNum === 0) {
        this.transNum = (-this.calList.length * 261) / 2;
        this.istrans = false;
        setTimeout(() => {
          this.istrans = true;
          this.transNum += 261;
          // this.actimg = this.imgdata[this.activeNum].img
        }, 1);
      } else {
        this.transNum += 261;
        // this.actimg = this.imgdata[this.activeNum].img
      }
    },
    goTalent(id,type,oParams) {
      this.$router.push({ path: "/detail", query: { id,type,oParams } });
    },
    // getTalents,
    getTalentsData() {
      GetTalents(this.Talentsparams).then((res) => {
        this.calList = res.data.data.list
          .slice(0, 5);
        this.calList = this.calList.concat(this.calList); //临时的 该用于获取数据后
        this.subList = res.data.data.list.slice(5,11)
          this.$nextTick(()=>{
            this.paly();
          })
      });
    },
    paly(){
      let that = this
      this.end()
      that.timer = setInterval(function(){
        that.next()
      },3000)
    },
    end(){
      clearInterval(this.timer)
    },
    //三项经验
    getSXJYData() {
      GetTalents(this.SXJYsparams).then((res) => {
        this.SXJYList = res.data.data.list;
      });
    },
    //能人事迹
    // getNRSJData(){
    //     GetTalents(this.NRSJsparams).then(res=>{
    //         this.NRSJfirst = res.data.data.list.filter(item=>item.sort === 1)[0];
    //         this.NRSJList = res.data.data.list.filter(item=>item.sort === 2).splice(0,4);
    //     })
    // }

    GetVideo() {
      const params = { currentPage: 1, pageSize: 5 , filter: { type: "能人事迹", infoType: 256581677129797 }, };
      GetVideoInfoPage(params).then((res) => {
        this.NRSJfirst = res.data.data.list[0];
        this.NRSJList = res.data.data.list.splice(1, 5);
      });
    },
      onVideoClear(){
      this.videoList = [];
    },
     onVideoPlay(path){
      let _src = Array.isArray(path)?path[0]:path;
      let option = {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: _src // url地址
          },
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
      if(Array.isArray(path)&&path.length>1){this.videoList = path};
      this.playerOptions = option;
      this.dialogVideo = true;
    },
  },
  mounted() {
    this.getTalentsData();
    this.getSXJYData();
    //this.getNRSJData();
    this.GetVideo();
  },
  beforeDestroy(){
    this.end();
  }
};
</script>
<style scoped>
.more{
    width: 240px;
    line-height: 42px;
    font-size: 16px;
    color: #898989;
    border: 2px solid #ccc;
    margin: 80px auto 40px auto;
    text-align: center;
    cursor: pointer;
    transition: all .8s;
}
.more:hover{
    background: rgb(211, 223, 240);
    /* border-color: #eee; */
    /* color: #fff; */
}
.more>span{
    margin-right: 20px;
}
.floor-title>span{
    letter-spacing: 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #3295f1;
}
.floor-title{
    color:#000;
    text-align: center;
    font-size: 40px;
    padding: 20px 0;
}
.f2_top{
  display: flex;
  height: 500px;
  overflow: hidden;
}
.v_title{
  font-size: 20px;
}
.v_info{
  font-size: 14px;
  line-height: 26px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
}
.h3{
  font-size: 30px;
  opacity: .6;
  margin-bottom: 20px;
}
.pb-20{
  padding-bottom: 20px;
}
.v_main{
  height: 100%;
  width: 750px;
}
.v_infos{
  width: calc(100% - 750px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
  text-align: center;
  background: #2f82ce;
  color: #fff;
}
.videos{
  /* top: 50%; */
  width: 100%;
  height: 100%;
  object-fit: fill;
}
  .T_video{
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
    width:100%;
    background: rgba(0,0,0,.3);
    text-align:center;
  }
  .T_video>img{
    width:22px;
    height: 22px;
    vertical-align: middle;
    transition: transform .2s;
    
  }
  .T_video>img:hover,.video_shade>img:hover{
    transform: scale(1.1,1.1);
    cursor: pointer;
  }
.inner-title {
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding: 12px;
  background: rgba(65, 153, 236, 0.8);
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: opacity 1s;
}
.pr-list {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.pr-item{
  width: 400px;
  height: 200px;
  box-shadow: 5px 0px 5px #aaa;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  cursor:pointer;
}
.pr-imgbox{
  width: 180px;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.pr-infos{
  width: calc(100% - 200px);
  padding: 10px;
}
.pr-info {
  font-size: 14px;
  font-weight: 600;
  text-indent: 20px;
  line-height: 20px;
  color: #666;
  /* margin: 4px 0; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.pr-title {
  font-size: 16px;
  font-weight: 600;
  text-indent: 20px;
  line-height: 24px;
  margin: 8px 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.pr-pic {
  height: 202px;
}
.star-intro {
  line-height: 24px;
  padding: 4px 16px;
  /* height: 80px; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.star-name {
  font-size: 18px;
  font-weight: 600;
  padding-left: 24px;
  line-height: 28px;
}
.star-info {
  font-size: 15px;
  /* height: 368px; */
}
.star-pic {
  height: 240px;
  padding: 5px;

}
.star-pic >>> .el-image{
  box-shadow: 0 0 5px #aaa;
  border-radius: 3px;
}
.isSlace {
  transform: scale(1.5);
  /* border: 2px solid #fff; */
  z-index: 20;
}
.isSlaceMin{
  transform: scale(1.2) translateX(-10%);
  z-index: 10;
}
.isSlaceMax{
  transform: scale(1.2) translateX(10%);
  z-index: 10;
}
.cal-smal-item.isSlace {
  /* background: #51c8e6; */
  color: #fff;
}
.istrans {
  transition: transform 1.2s;
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
.cal-btn {
  width: 50px;
  height: 80px;
  line-height: 80px;
  font-size: 50px;
  font: 700;
  position: absolute;
  top: 50%;
  border-radius: 3px;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.4);
}
.cal-btn:hover {
  background: rgba(255, 255, 255, 1);
}
.ceng {
  /* width: 100%;
  height: 240px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0; */
}
.cal-smal-item {
  width: 236px;
  height: 336px;
  overflow: hidden;
  margin-right: 25px;
  position: relative;
  transition: transform 1.2s;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -moz-transform-origin: 50% 50%;
  -webkit-transform-origin:center center;
  -o-transform-origin:50% 50%; 
}
.cal-small {
  height: 438px;
  padding-top: 34px;
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.floor1-main {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.f1-item:hover {
  width: 530px;
  box-shadow: 2px 2px 5px #777;
}
.f1-item {
  width: 310px;
  height: 400px;
  overflow: hidden;
  transition: all 0.4s;
  cursor: pointer;
  margin-top: 20px;
}
.floor1_pic {
  width: 310px;
  height: 400px;
  overflow: hidden;
}
.floor-intro {
  width: 220px;
  padding: 12px;
  line-height: 28px;
}
.f3-box:hover .f3-ceng {
  opacity: 1;
}
.f3-box:hover .inner-title {
  opacity: 0;
}
.f3-ceng > p:last-child {
  line-height: 1.8;
  text-indent: 20px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.f3-ceng > p:first-child {
  text-align: center;
  /* margin-bottom: 20px; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.f3-ceng span {
  /* border:1px solid #eee; */
  font-size: 20px;
  font-weight: 700;
}
.f3-ceng {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* color: rgb(65, 153, 236); */
  background: rgba(65, 153, 236, 0.8);
  font-size: 16px;
  color: #fff;
  padding: 36px 10px 20px 10px;
  opacity: 0;
  transition: all 1s;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

}
.innerbox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.f3-box {
  position: relative;
  height: 360px;
  width: 100%;
  margin-top: 20px;
}
.f2-smPic {
  height: 180px;
  width: 100%;
  overflow: hidden;
  margin-top: 15px;
  position: relative;
}
.f2-smPic .play-ceng i {
  font-size: 40px;
}
.floor2-text-box {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  height: 190px;
}
.floor2-text {
  text-indent: 26px;
  font-size: 16px;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.floor2-time {
  text-align: right;
  padding-right: 30;
  font-size: 16px;
}
.floor2-title .iconfont {
  color: #4199ec;
}
.floor2-title {
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  font-weight: 700;
}
.floor2-h {
  height: 580px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.play-ceng {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.play-ceng i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 60px;
  cursor: pointer;
}
.person-text {
  text-indent: 26px;
  font-size: 16px;
  overflow: hidden;
  line-height: 28px;
}
.person-name {
  height: 36px;
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
}
.mt-20 {
  margin-top: 20px;
}
.floorTitle:hover a {
  color: #4199ec;
}
.floorTitle {
  height: 42px;
  line-height: 42px;
  font-weight: 600;
  font-size: 22px;
  color: #0069c9;
  border-bottom: 1px solid #777;
}
</style>