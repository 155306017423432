<template>
    <div class="videoList">
        <div class="feature-carousel mt-20">
            <div class="carousel-box">
                <ul class="carousel-inner clearfix" :style="`width:${carData.length*355}px;transform:translateX(${translateNum}px)`">
                    <li class="carousel-item fl" v-for="(item,index) in carData" :key="index+'ii'">
                        <div class="carousel-item-pic" @click="changePlay(item)">
                            <video class="videos videotag" :src="item.videoUrl" width="100%" height="100%"></video>
                            <div class="ceng">
                                <i class="el-icon-video-play"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-prev" @click="prev" :class="{disabled:translateNum==0}"><i class="el-icon-arrow-left"></i></div>
            <div class="btn-next" @click="next" :class="{disabled:translateNum==carData.length*(-355)+1065}"><i class="el-icon-arrow-right"></i></div>
        </div>
    </div>
</template>
<script>
import { GetTouristRoutesPage } from '@/api/home'
export default {
    props:{datas:{type:Array}},
    data(){
        return{
            touristRoutes: [], //旅游线路
            carData:[{photoUrls:[]},{photoUrls:[]},{photoUrls:[]},{photoUrls:[]}],
            translateNum:0
        }
    },
    created(){
    },
    watch:{
        datas:{
            handler(a){
                this.carData = a
            },
            deep:true
        }
    },
    mounted(){
        // this.getTouristRoutes();
        this.carData = this.datas
    },
    methods:{
        //获取旅游线路(精品路线)
        getTouristRoutes(){
            const data = { filter:{routesTypes:'精品路线'}, currentPage:1, pageSize:10 };
            GetTouristRoutesPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.carData = data;
                }
            })
        },
        prev(){
            if(this.translateNum==0) return false;
            this.translateNum += 355;
        },
        next(){
            if(this.translateNum==this.carData.length*(-355)+1065) return false;
            this.translateNum -= 355;
        },
        changePlay(row){
            this.$emit('changePlay',row)
        }
    },
}
</script>
<style scoped>
.btn-prev{
    left: 0;
}
.btn-next{
    right: 0;
}
.btn-prev.disabled,.btn-prev.disabled:hover,.btn-next.disabled,.btn-next.disabled:hover{
    color: #ccc;
}
.btn-prev:hover,.btn-next:hover{
    color: #1c91ff;
    background: #e3effa;
}
.btn-prev,.btn-next{
    width: 40px;
    height: 100px;
    line-height: 100px;
    border-radius: 2px;
    text-align: center;
    font-size: 40px;
    color: #0169ca;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #f1f5f9;
    cursor: pointer;
}
.carousel-item-info .price{
    color:#f9773b;
}
.carousel-item-info p:first-child{
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-weight: bold;
}
.carousel-item-info p:last-child{
    line-height: 24px;
    font-size: 14px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
}
.carousel-item-info span{
    color: #666;
    margin-right: 20px;
}
.carousel-inner{
    width: 1800px;
    transition: transform 1.5s;
}
.carousel-item-info{
    height: 100px;
    padding: 12px 20px;
    background: #f1f5f9;
    transition: all 0.3s;
}
.carousel-item-pic{
    width: 340px;
    height: 227px;
    position: relative;
    cursor: pointer;
}
.ceng{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 227px;
}
.ceng>i{
    font-size: 40px;
    color: #fff;
    transform: translateY(20px);
}
.carousel-item{
    width: 340px;
    height: 227px;
    margin-right: 15px;
    /* background: #eee; */
    /* padding: 30px 5px; */
    box-sizing: border-box;
}
.carousel-item:hover{
    box-shadow: 5px 10px 8px #889;
}
.carousel-item:hover .carousel-item-info{
    background: #367bff;
    color: #fff;
}
.carousel-item:hover .carousel-item-info span{
    color: #fff;
}
.carousel-box{
    height: 227px;
    width: 1050px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.feature-carousel{
    height: 240px;
    width: 100%;
    position: relative;
    margin-top: 60px ;
}
</style>